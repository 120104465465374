import React from "react";

export const campaignObjectifs = [
  {
    id: 0,
    network: "Facebook",
    objective: [
      "Impression",
      "Interaction",
      "Vue Video",
      "Recrutement De Fans",
      "Traffic",
      "Story",
    ],
    creative: [
      {
        Impression: ["Post", "DarkPost"],
        Interaction: ["Post", "DarkPost"],
        "Vue Video": ["Post", "DarkPost"],
        Traffic: ["Post", "DarkPost"],
        Story: ["Story"],
        "Recrutement De Fans": ["Recrutement De Fans"],
      },
    ],
  },
  {
    id: 1,
    network: "Instagram",
    objective: ["Impression", "Interaction", "Story", "Vue Video", "Traffic"],
    creative: [
      {
        Impression: ["Post", "DarkPost"],
        Interaction: ["Post", "DarkPost"],
        "Vue Video": ["Post", "DarkPost"],
        Traffic: ["Post", "DarkPost"],
        Story: ["Story"],
        "Recrutement De Fans": ["Recrutement De Fans"],
      },
    ],
  },
  {
    id: 2,
    network: "Display",
    objective: ["Impression", "Traffic"],
    creative: [
      {
        Impression: ["Bannier"],
        Traffic: ["Bannier"],
      },
    ],
  },
  {
    id: 3,
    network: "Youtube",
    objective: ["Impression", "Vue Video"],
    creative: [
      {
        Impression: ["Video"],
        "Vue Video": ["Video"],
      },
    ],
  },
  {
    id: 4,
    network: "Snapchat",
    objective: ["Impression", "Swipup", "Geofiltre"],
    creative: [
      {
        Impression: ["Story"],
        Swipup: ["Story"],
        Geofiltre: ["Geofiltre"],
      },
    ],
  },
  {
    id: 5,
    network: "Linkedin",
    objective: ["Impression", "Traffic", "Inmail"],
    creative: [
      {
        Impression: ["Post", "DarkPost"],
        Traffic: ["Post", "DarkPost"],
        Inmail: ["Inmail"],
      },
    ],
  },
  {
    id: 6,
    network: "Search",
    objective: ["Traffic"],
    creative: [
      {
        Traffic: ["Annonce textuelle"],
      },
    ],
  },
];

export const ageFacebookInstagram = [
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
];

export const regions = [
  { title: "Algérie", key: "DZ" },
  { title: "Wilaya d'Alger", key: 900 },
  { title: "Wilaya de Blida", key: 913 },
  { title: "Wilaya de Béjaïa", key: 911 },
  { title: "Wilaya de Tizi Ouzou", key: 909 },
  { title: "Wilaya de Sétif", key: 907 },
  { title: "Wilaya d'Annaba", key: 928 },
  { title: "Wilaya d'Oran", key: 905 },
  { title: "Wilaya d'Adrar", key: 925 },
  { title: "Wilaya de Chlef", key: 932 },
  { title: "Wilaya de Laghouat", key: 918 },
  { title: "Wilaya d'Oum El Bouaghi", key: 921 },
  { title: "Wilaya de Batna", key: 901 },
  { title: "Wilaya de Biskra", key: 912 },
  { title: "Wilaya de Béchar", key: 929 },
  { title: "Wilaya de Bouira", key: 914 },
  { title: "Wilaya de Tamanrasset", year: 944 },
  { title: "Wilaya de Tébessa", key: 924 },
  { title: "Wilaya de Tlemcen", key: 910 },
  { title: "Wilaya de Tiaret", key: 908 },
  { title: "Wilaya de Djelfa", key: 915 },
  { title: "Wilaya de Jijel", key: 917 },
  { title: "Wilaya de Saïda", key: 906 },
  { title: "Wilaya de Skikda", key: 923 },
  { title: "Wilaya de Sidi Bel Abbès", key: 922 },
  { title: "Wilaya de Guelma", key: 916 },
  { title: "Wilaya de Constantine", key: 902 },
  { title: "Wilaya de Médéa", key: 903 },
  { title: "Wilaya de Mostaganem", key: 904 },
  { title: "Wilaya de M'Sila", key: 920 },
  { title: "Wilaya de Mascara", key: 919 },
  { title: "Wilaya d'Ouargla", key: 941 },
  { title: "Wilaya d'El Bayadh", key: 933 },
  { title: "Wilaya d'Illizi", key: 937 },
  { title: "Wilaya de Bordj Bou Arreridj", key: 930 },
  { title: "Wilaya de Boumerdès", key: 931 },
  { title: "Wilaya d'El Tarf", key: 4517 },
  { title: "Wilaya de Tindouf", key: 945 },
  { title: "Wilaya de Tissemsilt", key: 947 },
  { title: "Wilaya d'El Oued", key: 934 },
  { title: "Wilaya de Khenchela", key: 938 },
  { title: "Wilaya de Souk Ahras", key: 943 },
  { title: "Wilaya de Tipaza", key: 946 },
  { title: "Wilaya de Mila", key: 939 },
  { title: "Wilaya d'Aïn Defla", key: 926 },
  { title: "Wilaya de Naâma", key: 940 },
  { title: "Wilaya d'Aïn Témouchent", key: 927 },
  { title: "The Wilaya de Ghardaïa", key: 936 },
  { title: "Wilaya de Relizane", key: 942 },
];

export const cta = [
  { title: "En savoir plus", value: "En savoir plus" },
  { title: "Profiter de l’offre", value: "Profiter de l’offre" },
  { title: "Obtenir les horaires", value: "Obtenir les horaires" },
  { title: "Acheter", value: "Acheter" },
  { title: "S’inscrire", value: "S’inscrire" },
  { title: "Postuler maintenant", value: "Postuler maintenant" },
  { title: "Demander l’horaire", value: "Demander l’horaire" },
  { title: "Voir le menu", value: "Voir le menu" },
  { title: "S’abonner", value: "S’abonner" },
  { title: "Voir d’autres vidéos", value: "Voir d’autres vidéos" },
  { title: "Nous contacter", value: "Nous contacter" },
  { title: "Faire un don", value: "Faire un don" },
  { title: "Télécharger", value: "Télécharger" },
  { title: "Obtenir un devis", value: "Obtenir un devis" },
];
