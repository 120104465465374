import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Layout from "../components/Layout";
// import { navigate } from "@reach/router";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import { navigate } from "gatsby";
import { campaignObjectifs } from "../constants/campaignConstant";
import {
  Button,
  CircularProgress,
  Typography,
  TextField,
  MenuItem,
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";

function Inscription() {
  const data = {
    name: "",
    firstname: "",
    company: "",
    email: "",
    phone: "",
    message: "",
    typeDeFormation: "",
    horaire: "Week-end",
    tarif: "",
    nombreDePersonnes: "",
    edtion: "",
  };

  const [state, setstate] = useState(data);
  const [send, setSend] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("formation")) {
      let copy = { ...state };

      let localStorageInfo = JSON.parse(localStorage.getItem("formation"));

      // console.log(localStorageInfo);

      copy.dateDebut = localStorageInfo.dateDebut;
      copy.edtion = localStorageInfo.edition;
      copy.typeDeFormation = localStorageInfo.title;
      copy.dateDeClotureInscription = localStorageInfo.dateDeClotureInscription;
      copy.dateDeFin = localStorageInfo.dateDeFin;
      copy.dateDeRemise = localStorageInfo.dateDeRemise;
      copy.discount = localStorageInfo.discount;
      copy.price = localStorageInfo.price;
      copy.discountedPrices = localStorageInfo.discountedPrices;
      copy.referer = localStorageInfo.referer;
      setstate(copy);
    } else {
      navigate("/formations/");
    }
  }, []);

  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
    // console.log(state);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.phone.length < 9 || state.phone.length > 14) {
      let copy = { ...state };
      copy.phone = "";
      setstate(copy);
    } else {
      try {


  


        var xhr = new XMLHttpRequest();
        xhr.open(
          "POST",
          "https://313eccd64560dc93b005fc9863630610.m.pipedream.net"
        );
        xhr.send(JSON.stringify({ data: state }));

        setstate(data);
        setSend(true);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'FormationFormSubmit',
      "data": state
       });

        navigate("/thanks/");
      } catch (e) {
        console.error(e);
      }
    }
  };

  let nombreDePersonnes =
    state.tarif === "Entreprise" ? (
      <TextField
        type="number"
        onChange={handleChange}
        placeholder="Nombre De Personnes a inscrire"
        value={state.nombreDePersonnes}
        id="nombreDePersonnes"
        name="nombreDePersonnes"
        margin="normal"
        fullWidth
        required
      />
    ) : null;

  return (
    <Layout>
      <section className="contact-page">
        <article className="contact-form">
          <h3>Pré-inscription pour la formation</h3>
          <form onSubmit={handleSubmit} id="formation">
            <div className="form-group">
              <TextField
                onChange={handleChange}
                id="name"
                type="text"
                placeholder="Nom"
                name="name"
                value={state.name}
                margin="normal"
                fullWidth
                required
              />
              <TextField
                onChange={handleChange}
                id="firstname"
                type="text"
                placeholder="Prénom"
                name="firstname"
                value={state.firstname}
                margin="normal"
                fullWidth
                required
              />
              {/* <TextField
                type="text"
                id="company"
                onChange={handleChange}
                placeholder="Entreprise"
                value={state.company}
                name="company"
                margin="normal"
                fullWidth
              /> */}
              <TextField
                type="email"
                onChange={handleChange}
                placeholder="Email"
                id="email"
                value={state.email}
                name="email"
                margin="normal"
                fullWidth
                required
              />
              <TextField
                onChange={handleChange}
                placeholder="Télephone"
                type="number"
                value={state.phone}
                id="phone"
                name="phone"
                margin="normal"
                fullWidth
                required
              />

              <TextField
                onChange={handleChange}
                label="Tarifs"
                value={state.tarif}
                id="tarif"
                name="tarif"
                margin="normal"
                select
                fullWidth
                required
              >
                <MenuItem value="" disabled></MenuItem>
                <MenuItem value="Particulier">Particulier </MenuItem>
                <MenuItem value="Entreprise">Entreprise</MenuItem>
              </TextField>

              {nombreDePersonnes}

              <Button
                color="primary"
                variant="contained"
                size="large"
                className="submit-btn"
                type="submit"
              >
                Envoyer
              </Button>
            </div>
          </form>
        </article>
      </section>
    </Layout>
  );
}

export default Inscription;
